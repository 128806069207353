import lozad from 'lozad';
import 'bootstrap/dist/js/bootstrap.bundle';
import Swiper from "swiper";
import {Autoplay, Navigation, Pagination} from 'swiper/modules';
import Swal from 'sweetalert2';
import 'jquery-datetimepicker';
import {intlTelInput} from 'intl-tel-input/build/js/intlTelInput-jquery';
import {mask} from 'jquery-mask-plugin/dist/jquery.mask'


function date_picker_init() {
    jQuery('.datepicker__input').datetimepicker({
        format: 'd-m-Y',
        minDate: 0,
        timepicker: false
    });
}

function filterBlogsByHash(hash) {
    const allBlogs = document.querySelectorAll('.blog__card');
    const navItems = document.querySelectorAll('.blog__nav li');

    navItems.forEach(item => item.classList.remove('active'));

    if (hash) {
        allBlogs.forEach(blog => blog.style.display = 'none');
        const matchingBlogs = document.querySelectorAll(`.section-${hash}`);
        matchingBlogs.forEach(blog => blog.style.display = 'block');

        const activeNavItem = document.querySelector(`.blog__nav li a[href$="#${hash}"]`)?.parentElement;
        if (activeNavItem) activeNavItem.classList.add('active');
    } else {
        allBlogs.forEach(blog => blog.style.display = 'block');
        const allNavItem = document.getElementById('is-main');
        if (allNavItem) allNavItem.classList.add('active');
    }
}

(function ($) {
    jQuery(document).ready(function ($) {

        const observer = lozad();
        observer.observe();

        function intlTelInputInit() {
            $(".phone_intl").intlTelInput({
                initialCountry: "ua",
                separateDialCode: true,
                showSelectedDialCode: true,
                countrySearch: false,
                allowDropdown: true,
                utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@19.2.16/build/js/utils.js",
            });
        }

        intlTelInputInit();

        date_picker_init();

        filterBlogsByHash(window.location.hash.substring(1));

        $(window).on('hashchange', function () {
            filterBlogsByHash(window.location.hash.substring(1));
        });


        jQuery(document.body).on('click', '.quantity_swal__plus', function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            var input = $(this).closest('.quantity_swal').find('input[type="number"]');
            var min = input.attr('min');
            var max = input.attr('max');
            var oldValue = parseFloat(input.val());
            if (oldValue >= max) {
                var newVal = oldValue;
            } else {
                var newVal = oldValue + 1;
            }
            input.val(newVal);
            input.trigger("change");
        });
        jQuery(document.body).on('click', '.quantity_swal__minus', function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            var input = $(this).closest('.quantity_swal').find('input[type="number"]');
            var min = input.attr('min');
            var max = input.attr('max');
            var oldValue = parseFloat(input.val());

            if (oldValue <= max) {
                var newVal = oldValue;
            } else {
                var newVal = oldValue - 1;
            }

            if (newVal >= 1) {
                input.val(newVal);
                input.trigger("change");
            }
        });


        let open_popups = document.querySelectorAll('.courses-popup__open');

        open_popups.forEach((popup) => {
            popup.addEventListener('click', function () {
                let url = this.getAttribute('data-url'),
                    title = this.getAttribute('data-title');

                fetch(url)
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire({
                            title: title,
                            html: data.popup_html,
                            showCloseButton: true,
                            showConfirmButton: false,
                            showCancelButton: false,
                            onOpen: () => {

                                intlTelInputInit();

                                const form = document.querySelector('#swal_popup1');
                                form.addEventListener("submit", function (event) {
                                    event.preventDefault();

                                    let formData = new FormData(form);

                                    fetch(form.action, {
                                        method: 'POST',
                                        body: formData,
                                        headers: {
                                            'X-Requested-With': 'XMLHttpRequest'
                                        }
                                    })
                                        .then(response => response.json())
                                        .then(data => {
                                            if (data.success) {
                                                Swal.fire({
                                                    title: 'Success!',
                                                    text: data.message,
                                                    icon: 'success',
                                                    showCloseButton: true,
                                                    confirmButtonText: 'OK'
                                                }).then(() => {
                                                    window.location.href = data.redirect_url;
                                                });
                                            } else {
                                                // Display form errors in the popup
                                                let errorsHtml = '<ul>';
                                                Object.keys(data.errors).forEach(key => {
                                                    errorsHtml += `<li>${data.errors[key].join(', ')}</li>`;
                                                });
                                                errorsHtml += '</ul>';
                                                Swal.fire({
                                                    title: 'Error!',
                                                    html: errorsHtml,
                                                    icon: 'error'
                                                });
                                            }
                                        })
                                        .catch(error => console.error('Error in form submission:', error));
                                });
                            }
                        });
                    })
                    .catch(error => console.error('Error fetching popup HTML:', error));
            });
        });

        $('.callback-popup__open').on("click", function (e) {
            e.preventDefault();
            Swal.fire({
                html: `
                    <div class="swal2-inner">
                        <form action="" class="form">
                            <p class="text-center h2 mb-3">Contact us</p>
                            <p class="text-center fw-light h3 mb-4">Feel free to contack us if you have any questions</p>
                            <div class="mb-3 mb-lg-4">
                                <input class="form__input" type="text" placeholder="Name" required name="name">
                            </div>
                            <div class="mb-3 mb-lg-4">
                                <input class="form__input" type="email" placeholder="Email" required name="email">
                            </div>
                            <div class="mb-3 mb-lg-4">
                                <input class="form__input phone_intl" type="tel"  required name="tel">
                            </div>
                            <div class="mb-3 mb-lg-4">
                                <textarea name="message" class="form__input" placeholder="Message"></textarea>
                            </div>
                            <button type="submit" class="button w-100 mb-3">Send</button>
                            <p class="form__text-small text-center fw-light">By pressing the ''Send'' button you accept our Privacy Policy</p>
                        </form>
                    </div>
                  `,
                showCloseButton: true,
                showConfirmButton: false,
                showCancelButton: false,
                onOpen: () => {
                    intlTelInputInit();
                }
            })
        });

        $('.catering-popup__open').on("click", function (e) {
            e.preventDefault();
            Swal.fire({
                html: `
                    <div class="swal2-inner">
                        <form action="" class="form">
                            <p class="text-center h2 mb-3">Послуги з кейтерингу</p>
                            <p class="text-center fw-light h3 mb-4">Залиште заявку і ми зв'яжемося з Вами для обговорення деталей</p>
                            <div class="mb-3 mb-lg-4">
                                <input class="form__input" type="text" placeholder="Name" required name="name">
                            </div>
                            <div class="mb-3 mb-lg-4">
                                <input class="form__input" type="email" placeholder="Email" required name="email">
                            </div>
                            <div class="mb-3 mb-lg-4">
                                <input class="form__input phone_intl" type="tel"  required name="tel">
                            </div>  
                            <div class="mb-3 mb-lg-4">
                                <input class="form__input form__input_calendar datepicker__input" type="text" placeholder="Date" readonly required name="date">
                            </div>  
                            <div class="mb-3 mb-lg-4">
                                <p class="fw-light mb-3">Зазначте необхідні послуги</p>
                                <label class="form__checkbox-input mb-2">
                                    <input type="checkbox" name="checkbox1">
                                    <span>Кейтеринг</span>
                                </label>
                                <label class="form__checkbox-input mb-2">
                                    <input type="checkbox" name="checkbox2">
                                    <span>Виїзний бар</span>
                                </label>
                            </div>       
                            <button type="submit" class="button w-100 mb-3">Замовити</button>
                            <p class="form__text-small text-center fw-light">By pressing the ''Send'' button you accept our Privacy Policy</p>
                        </form>
                    </div>
                  `,
                showCloseButton: true,
                showConfirmButton: false,
                showCancelButton: false,
                onOpen: () => {
                    intlTelInputInit();
                    date_picker_init();
                }
            })
        });

        $('.catering-detail-popup__open').on("click", function (e) {
            e.preventDefault();
            Swal.fire({
                html: `
                    <div class="swal2-inner">
                        <div class="post_content mb-4 fw-light">
                            <h2>Шпажки</h2>
                            <p>Пропонуємо широкий асортимент легких закусок на шпажках.</p>
                            <ul>
                                <li>Капрезе</li>
                                <li>Грушка, хамон</li>
                                <li>Чедар, базилік, чорізо</li>
                                <li>Шинка, перець, оливка</li>
                                <li>Сир Лазур, прошуто, м'ята</li>
                                <li>Гарбуз, базилік, прошуто</li>
                            </ul>
                            <p>Завантажте PDF меню кейтерингу від HORECA School з детальнішою інформацією.</p>
                        </div>
                        <a href="https://drive.google.com/file/d/1JDzRaEcmiihTyh0-qqyyEFKzMb5mtFVt/view" target="_blank" class="button w-100">Завантажити PDF</a>
                    </div>
                  `,
                showCloseButton: true,
                showConfirmButton: false,
                showCancelButton: false,
            })
        });

        // Progress bar
        function updateProgressBar() {
            const progressBar = document.querySelector('.progress-bar');
            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const progress = (window.pageYOffset / totalHeight) * 100;
            progressBar.style.width = progress + '%';
        }

        updateProgressBar();
        window.addEventListener('scroll', updateProgressBar);
        window.addEventListener('resize', updateProgressBar);

        $.fn.sectionMenuInViewport = function () {
            let elementTop = $(this).offset().top;
            let elementBottom = elementTop + $(this).outerHeight() / 2;
            let viewportTop = $(window).scrollTop();
            let viewportBottom = viewportTop + $(window).height() / 2;
            return elementBottom > viewportTop && elementTop < viewportBottom;
        };

        function dotsMenu() {
            let sections = $('.dot_menu_item');
            $.each(sections, function (index, value) {
                if ($(value).sectionMenuInViewport()) {

                    $('.main-nav li a').removeClass('active')
                    $('.main-nav li a').each(function (i, val) {
                        if (index === i) {
                            $(val).addClass('active');
                        }
                    });
                }

            });

        }

        dotsMenu();
        $(window).on('resize scroll', function () {
            dotsMenu();
        });

        $('.navbar_toggler').on('click', function () {
            $('.header__nav').addClass('active')
            $('body').addClass('hidden_body');
            $('.js_nav_open').show();
        })

        $('.header__nav_close').on('click', function () {
            $('.header__nav').removeClass('active')
            $('body').removeClass('hidden_body');
            $('.js_nav_open').hide();
        })

        $(".js_nav_open").on('click', function () {
            $('body').removeClass('hidden_body');
            $(this).hide();
            $('.header__nav').removeClass('active');
        });

        $('.header__nav a').on('click', function () {
            $('.header__nav').removeClass('active')
            $('body').removeClass('hidden_body');
            $('.js_nav_open').hide();
        })

        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                $('.back-to-top').fadeIn('slow');
            } else {
                $('.back-to-top').fadeOut('slow');
            }
        });
        $('.back-to-top').click(function () {
            $('html, body').animate({scrollTop: 0}, 1500,);
            return false;
        });

        $('.anchor[href*="#"]:not([href="#"])').click(function () {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                let target = $(this.hash);
                let menuHeight = $('header').outerHeight();
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000);
                    return false;
                }
            }
        });


        const gallerySwiperSelector = document.querySelector('.gallery__slider');
        const gallerySwiper = new Swiper(gallerySwiperSelector, {
            modules: [Navigation, Pagination],
            slidesPerView: 1,
            loop: true,
            navigation: {
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                576: {
                    spaceBetween: 24,
                    slidesPerView: 2,
                    roundLengths: true,
                    centeredSlides: true,
                },
                768: {
                    spaceBetween: 36,
                    slidesPerView: 2,
                    roundLengths: true,
                    centeredSlides: true,
                },
                1200: {
                    spaceBetween: 60,
                    slidesPerView: 'auto',
                    roundLengths: true,
                    centeredSlides: true,
                }
            }
        });
    });
}(jQuery));